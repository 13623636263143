import React from 'react'

export default function Loader() {
  return (
    <div class="loaderBackdropContainer" style={{display:'none'}}>
        <div class="loaderContainer">
            <div class="loader"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            <p>PROCESSING</p>
        </div>
    </div>
  )
}
