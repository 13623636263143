import React, { useEffect, useState } from 'react';
import Logo from './logo.svg'
import LHLogo from './lh-logo.svg';
import HarLogo from './harrods.png';
import Rocks from './rocks.png';
import DesignYard from './designyard.png';
import APLogo from './ap-logo.svg';
import RVLogo from './rivolilogo.svg';
import ChatLogo from './chat.svg';
import HadMond from './Hadmond.png';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore"
import { firebaseDB } from '../../../lib/firebase';
// import { firebaseDBStaging } from '../../../lib/firebase-staging';
// import DomToImage from 'dom-to-image';

const Header = ({ showContactModal, message }) => {
  const product = useSelector((store) => store.product);
  const configuration = useSelector((store) => store.configuration);
  const [isDisabled, setIsDisabled] = useState(true);
  const [productId, setProductId] = useState(0);
  const [price, setPrice] = useState(0);
  const [rate, setRate] = useState(1.0);
  const [currency, setCurrency] = useState("GBP");

  const CURRENCIES = ["USD", "EUR", "GBP"];

  const _db = firebaseDB;

  useEffect(() => {
    console.log('project', product);
    switch (product.data.id) {
      case 185: setProductId(8236553765161); break;
      case 402: setProductId(8236574441769); break;
      case 405: setProductId(8236577947945); break;
      case 408: setProductId(8236587319593); break;
      case 83: setProductId(8236619301161); break;
      case 186: setProductId(8218132152617); break;
      default: setProductId(0); break;
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('configuration', JSON.stringify(configuration))
    localStorage.setItem('diamondType', JSON.stringify(configuration.diamondType))

    let priceDB = [];
    let material = configuration.pa_material.name;

    let diamondType = '';
    diamondType = configuration.diamondType == 'natural' ? 'Earth ' : '';

    if (material == "18kt Rose Gold") material = "18ct Recycled Rose Gold";
    if (material == "18kt Yellow Gold") material = "18ct Recycled Yellow Gold";
    let q = null;
    if (!product.data.id || message == "") {
      setPrice(0);
    }
    else {
      if (product.data.id == 186 || product.data.id == 185) {
        if (product.data.id == 186) {
          q = query(collection(_db, 'amanti'))
        }
        if (product.data.id == 185) {
          q = query(collection(_db, 'mayfair'))
        }
        onSnapshot(q, async (querySnapshot) => {
          console.log('query', querySnapshot.docs);
          priceDB = querySnapshot.docs.filter(doc => (
            doc.id == diamondType + material
          ))[0].data();

          let total = 0;
          configuration.message.split("").map((e) => {
            total += priceDB[e.toUpperCase()];
            console.log('total', total)
          });
          setPrice(total);
          setIsDisabled(false)
        })
      }
      else if (product.data.id == 83) {
        q = query(collection(_db, 'aquafiore-neck'));
        onSnapshot(q, async (querySnapshot) => {
          console.log('query', querySnapshot.docs);
          priceDB = querySnapshot.docs.filter(doc => (
            doc.id == material
          ))[0].data();
          let total = 0;
          let stonesNum = 0;
          let letters = configuration.message.trim().split("").filter((e) => e.charCodeAt() !== 160 && e.charCodeAt() !== 32);
          letters.map((e) => {
            if (priceDB[e.toUpperCase()]) {
              stonesNum += priceDB[e.toUpperCase()];
            }
          });
          let wordNum = 0;
          configuration.message.split("").map((e) => {
            console.log('ascill', e.charCodeAt())
            if (e.charCodeAt() == 160 || e.charCodeAt() == 32) {
              wordNum++;
            }
          });
          wordNum = wordNum + 1;
          console.log('stone number', stonesNum, wordNum);
          total = stonesNum * priceDB["stone"] + priceDB["basic"] + priceDB["spacer"] * (letters.length - wordNum) + priceDB["hexagon"] * (wordNum);
          console.log('lenghts', configuration.message.split('').filter((e) => e == null), ' ', configuration.message.trim().split(""));
          setPrice(total);
          setIsDisabled(false)
        })
      }
      else {
        if (product.data.id == 408) {
          q = query(collection(_db, 'aquafiore-earrings'))
        }
        if (product.data.id == 402) {
          q = query(collection(_db, 'aquafiore-bracelet'))
        }
        if (product.data.id == 405) {
          q = query(collection(_db, 'aquafiore-pendant'))
        }
        onSnapshot(q, async (querySnapshot) => {
          priceDB = querySnapshot.docs.filter(doc => (
            doc.id == material
          ))[0].data();
          console.log(priceDB);
          let total = 0;
          configuration.message.split("").map((e) => {
            total += priceDB[e.toUpperCase()];
            console.log('total', total)
          });
          setPrice(total);
          setIsDisabled(false)
        })
      }
    }
  }, [configuration])

  useEffect(() => {
    // getExchangeRate(currency).then((rate) => {
    //   setRate(rate);
    // });
    if (currency === "EUR") {
      setRate(1.205);
    }
    if (currency === "USD") {
      setRate(1.435);
    }
    if (currency === "GBP") {
      setRate(1.0);
    }
  }, [currency]);

  const getExchangeRate = async (toCurrency) => {
    try {
      const response = await axios.get(`https://v6.exchangerate-api.com/v6/2e5a8804e90bd775a81e405e/latest/GBP`);
      const rate = response.data.conversion_rates[toCurrency];
      console.log("rate", response.data.conversion_rates[toCurrency])
      return rate;
    } catch (error) {
      console.error(`Error fetching exchange rate: ${error}`);
      throw new Error('Could not fetch exchange rate');
    }
  };

  const buy = async () => {
    document.querySelector('.loaderBackdropContainer').style.display = 'flex'
    let canvas = document.querySelector(".canvas-two");
    const formData = new FormData();
    formData.append("file", canvas.toDataURL());

    // .post("http://localhost:5000/upload", formData)
    axios
      .post("https://staging-codeby-backend.vercel.app/upload", formData)
      .then((imageResponse) => {
        localStorage.setItem("Cloudinary Response>>>", JSON.stringify(imageResponse))
        // window.open(imageResponse.data.url, '_blank')

        // axios.post("http://localhost:5000/add-to-cart", { 
        axios.post("https://staging-codeby-backend.vercel.app/add-to-cart", {
          productId: productId,
          imageUrl: imageResponse.data.url,
        }).then((res) => {
          console.log('add to cart', res)
          localStorage.setItem('add to cart res', JSON.stringify(res))

          let productName = res?.data?.product?.title;
          let variants = res.data.product?.variants;
          let variant = "not_found";
          // variants.map((v) => {
          //   if (parseInt(v.price) == parseInt(price)) {
          //     variant = v;
          //   }
          // });
          // console.log('variant', variant);
          if (variant == "not_found") {
            // create one
            // .post("http://localhost:5000/create-cart", {
            axios
              .post("https://staging-codeby-backend.vercel.app/create-cart", {
                productId: productId,
                price: price,
                imageUrl: imageResponse.data.url,
              })
              .then(function (response) {
                localStorage.setItem('New Variant Created', JSON.stringify(response))
                console.log('from create cart')
                variant = response.data.variant;
                console.log(variant)

                redirectToCart(productName, variant, "new")

              })
              .catch(function (error) {
                console.log(error);
                console.log(error.response);
              });
          } else {

            redirectToCart(productName, variant, "existing")

          }
        });
      })
  }

  const roundToNearestFiveOrZero = (number) => {
    // Convert the number to a string to easily access the last digit
    const strNumber = number.toString();
    const lastDigit = parseInt(strNumber.slice(-1), 10);

    // Determine how to round based on the last digit
    let roundedNumber;
    if (lastDigit < 3) {
      // Round down to the nearest multiple of 10
      roundedNumber = number - lastDigit;
    } else if (lastDigit < 8) {
      // Round to the nearest multiple of 5
      roundedNumber = number - lastDigit + 5;
    } else {
      // Round up to the nearest multiple of 10
      roundedNumber = number - lastDigit + 10;
    }

    return roundedNumber;
  }

  const redirectToCart = (productName, variant, type,) => {
    localStorage.setItem(`${type} variant.id`, JSON.stringify(variant.id))
    if (productName.includes("Aquafiore")) {
      let colorType = configuration.pa_stone.name
      let colorName;
      let customColors;

      if (colorType === "At your choice") {
        colorName = ""
        customColors = configuration.pa_stone.choice.map((color) => {
          return color.value.name
        })
        localStorage.setItem(' customColors', JSON.stringify(customColors))
        window.location.assign(`https://codebyedge.co.uk/cart/add?id=${variant.id}&quantity=1&properties[message]=${encodeURIComponent(configuration.message)}&properties[finish]=${encodeURIComponent(configuration.pa_material.name)}&properties[size]=${encodeURIComponent(configuration.pa_size.name)}&properties[color]=${encodeURIComponent("Custom Colors")}&properties[_custom-colors]=${encodeURIComponent(customColors)}`, "");
      }
      else {
        colorName = !configuration.pa_stone.selected_theme
          ? configuration.pa_stone.choice[0].value.name
          : configuration.pa_stone.selected_theme
        window.location.assign(`https://codebyedge.co.uk/cart/add?id=${variant.id}&quantity=1&properties[message]=${encodeURIComponent(configuration.message)}&properties[finish]=${encodeURIComponent(configuration.pa_material.name)}&properties[size]=${encodeURIComponent(configuration.pa_size.name)}&properties[color]=${encodeURIComponent(colorType + " - " + colorName)}`, "");

      }


    }
    else {
      let updatedDiamondType = !configuration.diamondType
        ? "Lab"
        : configuration.diamondType === "lab"
          ? "Lab"
          : "Natural"

      window.location.assign(`https://codebyedge.co.uk/cart/add?id=${variant.id}&quantity=1&properties[message]=${encodeURIComponent(configuration.message)}&properties[finish]=${encodeURIComponent(configuration.pa_material.name)}&properties[size]=${encodeURIComponent(configuration.pa_size.name)}&properties[type]=${encodeURIComponent(updatedDiamondType)}`, "");

    }
  }

  return (
    <div className="flex md:flex-row lg:flex-row header-height  flex-col-reverse content-center gap-4 justify-center lg:mt-6" id='header'>
      <div className="md:flex basis-4/12 justify-center items-center hidden">
        <div className="">
          <a role="button" tabIndex="0" href="https://codebyedge.co.uk" className="a a-link flex">
            <img src={Logo} alt="logo" srcSet="" width="100" />
            {window.location.href.includes('harrods') && (
              <img src={HarLogo} className="ml-2 hr-logo" alt="hr-logo" srcSet='' />
            )}
            {window.location.href.includes('designyard') && (
              <img src={DesignYard} className="ml-2 hr-logo" alt="designyard-logo" srcSet='' />
            )}
            {window.location.href.includes('rocks') && (
              <img src={Rocks} className="ml-2 hr-logo" alt="rocks-logo" srcSet='' />
            )}
            {window.location.href.includes('rivoli') && (
              <img src={RVLogo} className="ml-3 hr-logo" alt="rocks-logo" srcSet='' />
            )}
            {window.location.href.includes('lister') && (
              <img src={LHLogo} className="ml-2 lh-logo" alt="lh-logo" srcSet='' />
            )}
            {window.location.href.includes('anjapotze') && (
              <img src={APLogo} className="ml-3 ap-logo" alt="lh-logo" srcSet='' />
            )}
            {window.location.href.includes('hadmond') && (
              <img src={HadMond} className="ml-3 ap-logo" alt="lh-logo" srcSet='' />
            )}

          </a>
        </div>
      </div>
      <div className="md:basis-4/12 basis-8/12 flex justify-center items-center setting-menu-tab" onClick={() => showContactModal()}>
        <button className="bg-gray-100 hover:bg-gray-200 cbe-btn-text-green cbe-btn-text-font py-2 px-12 rounded-none h-3/4">
          <div className="flex flex-row content-center justify-start gap-x-2">
            <div className="basis-1/4">
              <img className="cbe-btn-svg-fill" src={ChatLogo} alt="chat" srcSet="" width="24" />
            </div>
            <div className="basis-3/4">
              <p>Talk to Us</p>
            </div>
          </div>
        </button>
      </div>
      <div className='flex items-center justify-center'>
        <select className="border border-[1px] border-black px-2 h-12" value={currency} onChange={(e) => setCurrency(e.target.value)}>
          {CURRENCIES.map((currency) => (
            <option key={currency} value={currency}>{currency}</option>
          ))}
        </select>
      </div>
      <div className="basis-4/12 flex pr-1 justify-end">
        <div className="md:basis-9/12 w-full flex justify-center">
          <button className={`${isDisabled ? "disabledBtn" : "bg-[#183e3f]"} hover:bg-teal-700 w-full py-8 px-4 rounded-lg justify-self-end text-2xl font-extrabold text-white`} disabled={isDisabled} onClick={() => buy()}>
            <div className="flex md:flex-col lg:flex-row items-center px-8 justify-between" >
              {currency === "USD" && <p style={{ fontFamily: "Comorant" }}>${roundToNearestFiveOrZero(Math.round(price * rate))}</p>}
              {currency === "EUR" && <p style={{ fontFamily: "Comorant" }}>€{roundToNearestFiveOrZero(Math.round(price * rate))}</p>}
              {currency === "GBP" && <p style={{ fontFamily: "Comorant" }}>£{price}</p>}
              <p style={{ fontFamily: "Cormorant Garamond" }}>Buy Now</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header;
