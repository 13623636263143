import React from 'react';
import PropTypes from 'prop-types';
import { AllHtmlEntities } from 'html-entities';
import { Next } from '../../../assets';

const entities = new AllHtmlEntities();

class ProductCard extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    product: PropTypes.instanceOf(Object).isRequired,
    img: PropTypes.string,
  };

  static defaultProps = {
    selected: false,
  };

  constructor(props) {
    super(props);
    const { selected } = props;
    this.state = {
      active: selected,
    };
  }

  optionPressed = () => {
    const { onChange } = this.props;
    onChange(this);
  };

  optionToggle = (state) => {
    this.setState({ active: state });
  };

  render() {
    const { product } = this.props;
    const { active } = this.state;
    const { img } = this.props;

    let productSelectedClass = 'product__image';
    let ctaSelectedClass = 'product__cta';
    if (active) {
      productSelectedClass = 'product__image product__image--selected';
      ctaSelectedClass = 'product__cta product__cta--selected';
    }

    return (
      <div
        className="product"
        onKeyUp={(key) => {
          if (key.keyCode === 13) {
            this.selectProduct();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {product.prodImg && (
          <div className={productSelectedClass}>
            <div className="product__image--content flex flex-col-reverse h-[400px]" style={{ backgroundImage: `url('${img}')` }} >
              <div className='flex flex-col items-center'>
                <h1 className='tracking-widest text-center text-white uppercase text-[25px] lg:text-[30px] font-thin' style={{ fontFamily: 'Cormorant Garamond', wordBreak: 'break-word' }}>{product.name}</h1>
                <div className='uppercase p-3 bg-white my-4 text-[#938c8c] hover:text-black' style={{ fontFamily: 'Cormorant serif' }} onClick={this.optionPressed}>Create Now</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}


export default ProductCard;
