import React from 'react';
import { Provider } from 'react-redux';
import { store } from './lib/env';
import Layout from './components/Layout';
import Loader from './components/Loaders/Loader';
import './index.scss';


function App() {
  return (
    <Provider store={store}>
      <Layout />
      <Loader />
    </Provider>
  );
}

export default App;
